<template>

  <el-form :class="'form-read-only'"
           :model="objData"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">

        <div class="row g-9 mb-7">
          <div v-if="objData.first_name" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">First Name</label>
            <el-form-item prop="first_name" >
              <el-input v-model="objData.first_name" placeholder="First Name"/>
            </el-form-item>
          </div>
          <div  v-if="objData.last_name" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Last Name</label>
            <el-form-item prop="last_name" >
              <el-input v-model="objData.last_name" placeholder="Last Name"/>
            </el-form-item>
          </div>
          <div v-if="objData.email" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Email</label>
            <el-form-item prop="email" >
              <el-input v-model="objData.email" placeholder="Email"/>
            </el-form-item>
          </div>
        </div>
        <div  class="row g-9 mb-7">
          <div v-if="objData.phone_code" class="col-md-1 fv-row phone-code" >
            <label class="required fs-6 fw-bold mb-2">Code</label>
            <el-form-item prop="phone_code" >
              <el-select v-model="objData.phone_code" placeholder="Select" style="width: 110px">
                <el-option v-for="phoneCode in phoneCodes" :label="phoneCode.phone_code" :value="phoneCode.phone_code"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="objData.phone" class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Phone</label>
            <el-form-item prop="phone" >
              <el-input v-model.number="objData.phone" placeholder="Phone"></el-input>
            </el-form-item>
          </div>

          <div  v-if="objData.city_ids" class="col-md-4 fv-row form-can-read">
            <label class="required fs-6 fw-bold mb-2">Cities</label>
            <el-form-item prop="city_ids" >
              <el-cascader class="full-width" v-model="objData.city_ids" placeholder="Select Cities"
                           :options="countries" :props="{multiple: true}" collapse-tags clearable filterable/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">

          <div v-if="objData.provider_rate" class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Provider Ratio</label>
            <el-form-item prop="provider_rate" :rules="rules['rate']">
              <el-input v-model="objData.provider_rate" placeholder="%" class="mb-2" style="width: 80px"></el-input>
            </el-form-item>
            <el-progress type="circle" :percentage="objData.provider_rate"  />
          </div>
          <image-comp v-if="objData.image" :element="objData.image" v-model="objData.image" col="col-md-3 fv-row" title="Image"></image-comp>
          <div v-if="objData.is_active" class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="objData.is_active"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div  class="col-md-4">
            <image-comp v-if="objData.identity_card" :element="objData.identity_card" v-model="objData.identity_card" col="col-md-6 fv-row"
                        title="Identity Card"></image-comp>
            <div v-if="objData.identity_card_expire_at" class="col-md-6 fv-row mt-3">
              <el-date-picker class="mt-4" value-format="YYYY-MM-DD" format="YYYY-MM-DD"  :disabled-date="disabledDate"
                              v-model="objData.identity_card_expire_at" type="date" placeholder="Pick expire date"/>
            </div>
            <div v-if="objData.identity_card_status" class="col-md-8 fv-row mt-3">
              <label class="required fs-6 fw-bold mb-2">Status</label>
              <el-form-item prop="identity_card_status" >
                <el-select v-model="objData.identity_card_status" class="select-display-block">
                  <el-option v-for="documentStatus in documentStatuses" :label="documentStatus.label"
                             :value="documentStatus.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-4">
            <image-comp v-if="objData.driver_license" :element="objData.driver_license" v-model="objData.driver_license" col="col-md-6 fv-row"
                        title="Driver License"></image-comp>
            <div v-if="objData.driver_license_expire_at" class="col-md-6 fv-row mt-3">
              <el-date-picker class="mt-4" value-format="YYYY-MM-DD" format="YYYY-MM-DD"  :disabled-date="disabledDate"
                              v-model="objData.driver_license_expire_at" type="date" placeholder="Pick expire date"/>
            </div>
            <div v-if="objData.driver_license_status" class="col-md-8 fv-row mt-3">
              <label class="required fs-6 fw-bold mb-2">Status</label>
              <el-form-item prop="driver_license_status" >
                <el-select v-model="objData.driver_license_status" class="select-display-block">
                  <el-option v-for="documentStatus in documentStatuses" :label="documentStatus.label"
                             :value="documentStatus.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-4">
            <image-comp v-if="objData.vehicle_license" :element="objData.vehicle_license" v-model="objData.vehicle_license" col="col-md-6 fv-row" title="Vehicle License"></image-comp>
            <div v-if="objData.vehicle_license_expire_at" class="col-md-6 fv-row mt-3">
              <el-date-picker class="mt-4" value-format="YYYY-MM-DD" format="YYYY-MM-DD"  :disabled-date="disabledDate"
                              v-model="objData.vehicle_license_expire_at" type="date" placeholder="Pick expire date"/>
            </div>
            <div v-if="objData.vehicle_license_status" class="col-md-8 fv-row mt-3">
              <label class="required fs-6 fw-bold mb-2">Status</label>
              <el-form-item prop="vehicle_license_status" >
                <el-select v-model="objData.vehicle_license_status" class="select-display-block">
                  <el-option v-for="documentStatus in documentStatuses" :label="documentStatus.label"
                             :value="documentStatus.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>

  </el-form>
</template>

<script>
import {defineComponent} from "vue";
import ImageComp from "@/components/admin/general/ImageComp";

export default defineComponent({
  name: "provider-activity-log-comp",
  components: {ImageComp},
  props: ['objData','countries','phoneCodes','documentStatuses'],
  setup(props) {

    return {
    };
  },

});
</script>


<style>
.selected-item {
  background-color: #bfffcc;
}
</style>